
.home-h2{
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 12px;
    line-height: 1.2;
    color: #4B4D75;

    @media (max-width: 1159px){
        font-size: 26px;
    }
    @media (max-width: 767px){
        font-size: 22px;
    }
}
.th-15-1{
    padding: 17px 17px 17px 3.5vw;
    display: flex;
    background-color: var(--color-2);
    position: relative;

    @media (max-width: 767px){
        padding: 0;
    }
}
.th-15-1-text{
    padding: 33px 0 33px 0;
    flex-grow: 1;
    position: relative;
    z-index: 1;

    @media (max-width: 1159px){
        padding: 24px 0 24px 0;
    }
    @media (max-width: 767px){
        padding: 0;
    }
}
.th-15-1-text-content{
    background-color: #ffffff;
    padding: 2vw 2vw 2vw 8vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 1159px){
        padding: 2vw 2vw 2vw 3vw;
    }
    @media (max-width: 767px){
        padding: 10vw 5vw 10vw 7vw;
        backdrop-filter: brightness(0.8);
        background-color: transparent;
    }
    h1{
        font-weight: 700;
        color: #4B4D75;
        text-transform: capitalize;
        margin-bottom: 0.4em;
        max-width: 90%;
        font-size: 3.3vw;

        &:lang(hy){

            @media (min-width: 768px){
                font-size: 3vw;
            }
        }
        @media (max-width: 767px){
            font-size: 7.3vw;
            max-width: 100%;
            color: #ffffff;
        }
    }
    p{
        font-size: 20px;
        color: #4B4D75;
        margin-bottom: 3vw;

        @media (max-width: 1159px){
            font-size: 18px;
            margin-bottom: 2vw;
        }
        @media (max-width: 767px){
            margin-bottom: 8vw;
            color: #ffffff;
        }
    }
    .btn{

        @media (max-width: 1159px){
            font-size: 16px;
        }
    }
}
.th-15-1-img{
    width: 49.86%;
    object-fit: cover;

    @media (max-width: 767px){
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .5;
    }
}
.th-15-2{
    background: #F7F7FF;
    padding: 60px 0;

    @media (max-width: 767px){
        padding: 12px 0;
    }
}
.th-15-2-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 767px){
        flex-direction: column-reverse;
    }
}
.th-15-2-left{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 50%;
    padding: 12px 0;

    @media (max-width: 767px){
        width: 100%;
        max-width: 482px;
    }
}
.th-15-2-left-item{
    background-color: #ffffff;
    padding: 24px 36px;
    width: 47%;
    text-align: center;
    margin: 16px 0;

    &:lang(hy){

        @media (min-width: 1160px){
            padding: 24px;
        }
    }
    @media (max-width: 1159px){
        padding: 24px 12px;
        width: 48%;
        margin: 7px 0;
    }
    img{
        height: 70px;
        margin-bottom: 20px;
        width: auto;
        object-fit: contain;

        @media (max-width: 1159px){
            margin-bottom: 15px;
        }
    }
    p{
        color: #4B4D75;
        font-size: 20px;
        margin-bottom: 0;
        line-height: 1.3;

        &:lang(hy){

            @media (min-width: 1160px){
                font-size: 18px;
            }
        }
        @media (max-width: 1159px){
            font-size: 18px;
        }
        @media (max-width: 767px){
            font-size: 15px;
        }
    }
}
.th-15-2-right{
    width: 50%;
    max-width: 482px;
    padding: 12px 0 12px 36px;

    @media (max-width: 767px){
        width: 100%;
        padding: 12px 0 12px 0;
    }
    > p{
        color: #4B4D75;
        font-size: 20px;
        line-height: 1.6;
        margin-bottom: 0;

        @media (max-width: 1159px){
            font-size: 18px;
            line-height: 1.5;
        }
        @media (max-width: 767px){
            font-size: 16px;
        }
    }
}
.th-15-3{
    padding: 72px 0;

    @media (max-width: 767px){
        padding: 12px 0;
    }
}
.th-15-3-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 767px){
        flex-direction: column-reverse;
        max-width: 480px;
        margin: 0 auto;
    }
}
.th-15-3-img{
    padding: 12px 0;
    width: 445px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;

    @media (max-width: 1159px){
        width: 350px;
    }
    @media (max-width: 767px){
        width: 100%;
        max-width: 420px;
    }
    > img{

        &:nth-child(1){
            width: 255px;
            height: 260px;
        }
        &:nth-child(2){
            margin-left: 95px;
            width: 255px;
            height: 260px;

            @media (max-width: 1159px){
                margin-left: 25px;
            }
        }
        &:nth-child(3){
            position: absolute;
            top: 66px;
            right: 0;
            width: 160px;
            height: 354px;
        }
    }
}
.th-15-3-text{
    flex-grow: 1;
    max-width: 557px;
    padding: 12px 0 12px 36px;
    color: #4B4D75;

    @media (max-width: 1159px){
        max-width: 370px;
    }
    @media (max-width: 767px){
        max-width: none;
        padding: 12px 0 12px 0;
    }
    > p{
        font-size: 20px;
        line-height: 1.6;

        @media (max-width: 1159px){
            font-size: 16px;
            line-height: 1.4;
        }
    }
    ul{
        font-size: 20px;
        font-weight: 700;
        margin-top: 48px;
        margin-bottom: 0;
        padding-left: 30px;

        @media (max-width: 1159px){
            padding-left: 22px;
            line-height: 1.2;
            margin-top: 30px;
            margin-bottom: 0;
            font-size: 16px;
        }
        li{
            margin-top: 16px;
        }
    }
}
.th-15-4{
    background: #5A5C82;
    padding: 48px 0;
    color: #ffffff;

    @media (max-width: 767px){
        padding: 24px 0 12px;
    }
    .home-h2{
        color: #ffffff;

        @media (max-width: 767px){
            margin-bottom: 0;
        }
    }
}
.th-15-5{
    padding: 60px 0;

    @media (max-width: 767px){
        padding: 12px 0 12px;
    }
}
.th-15-5-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 767px){
        max-width: 445px;
        margin: 0 auto;
    }
}
.th-15-5-text{
    padding: 12px 0;
    width: 46%;
    max-width: 445px;

    @media (max-width: 767px){
        width: 100%;
        margin-bottom: 24px;
    }
    > p{
        font-size: 20px;
        color: #4B4D75;

        @media (max-width: 1159px) {
            font-size: 16px;
        }
    }
}
.th-15-5-img{
    padding: 12px;
    background-color: #B07AB4;
    width: 50%;
    max-width: 538px;

    @media (max-width: 767px){
        width: 100%;
    }
}
.th-15-6{
    padding: 48px 0;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 767px){
        padding: 36px 0;
    }
    .home-h2{
        margin-bottom: 36px;
        display: flex;
        align-items: center;

        img{
            margin-right: 18px;
        }
    }
}
.th-15-6-container{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}
.th-15-6-item{
    display: flex;
    margin-bottom: 48px;
    max-width: 813px;
    width: 100%;

    @media (max-width: 767px){
        flex-direction: column;
        max-width: 320px;
        margin: 0 auto 36px;
    }
    &:nth-child(even){
        margin-left: auto;
        margin-bottom: 0;
    }
    > img{
        width: 255px;
        flex: 0 0 255px;
        object-fit: cover;
        margin-right: 24px;

        @media (max-width: 767px){
            width: 100%;
            flex: 0 0 100%;
        }
    }
}
.th-15-6-item-info{
    padding: 24px;
    background-color: #F7F7FF;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    p{
        color: #555557;
        margin-bottom: auto;
        padding-bottom: 15px;
    }
    h3{
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 0;
    }
    h4{
        color: #030303;
        font-size: 16px;
        margin-top: 3px;
        margin-bottom: 0;
    }
}

.theme-faq{
    color: #ffffff;
    padding: 72px 0;
    position: relative;
    background: #34354D;

    @media (max-width: 767px){
        padding: 36px 0;
    }
    .home-h2{
        color: #ffffff;
    }
}
.theme-15-form{
    padding: 48px 0;
    background: #34354D;
    color: #ffffff;

    @media (max-width: 767px){
        padding: 24px 0;
    }

    .theme-all-form-bg{
        opacity: .2;
    }
    .home-h2{
        color: #ffffff;
    }
}
