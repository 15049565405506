@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-300.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-regular.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-500.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-700.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-900.woff2') format('woff2');
}

@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 300;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-300.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 400;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-regular.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 500;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-500.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 600;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-600.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 700;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-700.woff2') format("woff2");
}

body{
    font-family: Roboto, NotoSansArmenian, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@import 'home';
@import '../../global/tenants-global';


:root {
    --nav-background: #ffffff;
    --nav-link: #000000;
    --nav-link-hover: #B07AB4;


    --btn1-background-color: #B07AB4;
    --btn1-background-hover-color: #9e6da1;
    --btn1-text-color: #ffffff;
    --btn1-text-hover-color: #ffffff;

    --btn1-outline-background-color: #ffffff;
    --btn1-outline-background-hover-color: #B07AB4;
    --btn1-outline-text-color: #B07AB4;
    --btn1-outline-text-color-hover: #ffffff;
    --btn1-outline-border-color: #B07AB4;
    --btn1-outline-border-hover-color: #B07AB4;


    --btn2-background-color: #5A5C82;
    --btn2-background-hover-color: #4b4c6c;
    --btn2-text-color: #ffffff;
    --btn2-text-hover-color: #ffffff;

    --btn2-outline-background-color: #ffffff;
    --btn2-outline-background-hover-color: #5A5C82;
    --btn2-outline-text-color: #5A5C82;
    --btn2-outline-text-color-hover: #ffffff;
    --btn2-outline-border-color: #5A5C82;
    --btn2-outline-border-hover-color: #5A5C82;


    --color-1: #B07AB4;
    --color-2: #5A5C82;


    --course-background: #5A5C82;
    --course-background-hover: #4c4d6d;
    --course-color: #ffffff;
    --course-color-hover: #ffffff;
    --course-color-hr: #ffffff;
    --course-color-hr-hover: #ffffff;
    --course-border: #C4C4C4;
    --course-radius: 4px;

}
h1,h2,h3,h4,h5,h6, p{
    min-height: 17px;
}


.btn{
    border-radius: 70px;
    font-size: 18px;
    padding: 0.55em 1.4em;
    letter-spacing: 0;

    @media (max-width: 1159px){
        font-size: 16px;
    }
}

.home-course-item-block hr{
    display: none;
}
.home-course-section > h2{
    font-weight: 700;
    font-size: 36px;
}


.bg-landing{

    .landing-container-full{
        background: #F7F7FF;
    }
}

.theme-all-info-section-1{
    background: #F7F7FF;
    padding: 68px 20px 72px;
}
.theme-15-info-section-new{
    padding: 35px 12px;

    @media (max-width: 767px){
        padding: 25px 12px;
    }

    .theme-all-info-section-1-container, .theme-all-info-section-2-container{
        padding: 35px 12px;

        @media (max-width: 767px){
            padding: 25px 12px;
        }
    }
}
